import React from 'react';

import Button from '../components/base/Button';
import FullPageCenter from '../components/base/FullPageCenter';
import Title from '../components/base/Title';
import App from '../components/layout/App';
import { colors, font, spacing } from '../styles/theme';

const NotFoundPage = (): JSX.Element => (
  <App>
    <FullPageCenter>
      <div style={{ textAlign: 'center' }}>
        <Title color={colors.primary[500]} size={font.size.xxxl}>
          Erreur 404
        </Title>
        <p>Page non trouvée</p>
        <div style={{ marginTop: spacing.l }}>
          <Button to="/">Home</Button>
        </div>
      </div>
    </FullPageCenter>
  </App>
);

export default NotFoundPage;
