import { rem } from 'polished';
import React, { FC } from 'react';
import styled, { ThemeProps } from 'styled-components';

import {
  breakpoint,
  DefaultThemeProps,
  lineHeight,
  mediaQueryMax,
} from '../../../styles/theme';

interface TitleProps extends ThemeProps<DefaultThemeProps> {
  color?: string;
  size?: number;
}

const TitleEl = styled.div<TitleProps>`
  line-height: ${lineHeight.s};
  font-weight: ${(props): string => props.theme.font.weight.bold};
  font-size: ${(props): string => rem(props.size || props.theme.font.size.l)};
  ${mediaQueryMax(breakpoint.tablet)} {
    font-size: ${(props): string =>
      rem((props.size || props.theme.font.size.l) * 0.9)};
  }
  color: ${(props): string => props.color || props.theme.colors.neutral[700]};
  &::first-letter {
    text-transform: uppercase;
  }
  @media print {
    color: ${(props): string => props.theme.colors.print.base};
  }
`;

/**
 * Title
 *
 * @param {object} props
 * @param {string} props.as
 * @param {React.ReactNode} props.children
 * @param {string} props.className
 * @param {string} props.color
 * @param {number} props.size
 * @param {object} props.style
 * @returns {React.ReactElement}
 */
const Title: FC<{
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'div';
  className?: string;
  color?: string;
  size?: number;
  style?: any;
}> = ({ as = 'div', children, className, color, size, style }) => (
  <TitleEl
    as={as}
    className={className}
    color={color}
    size={size}
    style={style}
  >
    {children}
  </TitleEl>
);

export default Title;
