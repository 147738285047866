import { rem } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { header } from '../../../styles/theme';

const FullPageCenterEl = styled.div`
  min-height: calc(100vh - ${rem(header.height.base)});
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

/**
 * FullPageCenter
 *
 * @param {object} props
 * @param {React.ReactElement} props.children
 * @returns {React.ReactElement}
 */
const FullPageCenter = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => <FullPageCenterEl>{children}</FullPageCenterEl>;

FullPageCenter.defaultProps = {
  children: null,
};

FullPageCenter.propTypes = {
  children: PropTypes.node,
};

export default FullPageCenter;
